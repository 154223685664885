import React from "react";

function YoEventLogo({ small = false }) {
  if (small)
    return (
      <svg width="131" height="32" viewBox="0 0 131 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_44_470)">
          <mask id="mask0_44_470" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
            <path d="M32 0H0V32H32V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_44_470)">
            <path
              d="M13.8565 14.9622H19.5798V22.8823H11.6597L13.8528 14.9622H13.8565ZM26.5632 0L20.4799 9.4042H14.7051L17.3868 0H0V32H32V0H26.5632Z"
              fill="white"
            />
          </g>
        </g>
        <path
          d="M43.006 16.988V2.24879H49.7036L51.3612 6.50479L52.1228 8.90159L52.8844 6.50479L54.542 2.24879H61.1948V16.988H56.446V10.0216L56.6028 6.99759L55.55 9.99919L53.0636 15.9576H50.5772L48.0908 9.97679L47.0604 7.01999L47.2172 9.99919V16.988H43.006Z"
          fill="white"
        />
        <path
          d="M63.2794 21.916H63.1002V17.996H63.2794C65.2506 17.996 65.9898 17.8168 66.6842 16.9656L65.833 16.8984L62.4058 6.28079H67.3114L68.1626 9.81999L68.8794 13.2024L69.6186 9.81999L70.4698 6.28079H74.7034L71.9706 15.308C70.4474 20.3256 67.7146 21.916 63.2794 21.916Z"
          fill="white"
        />
        <path
          d="M76.3217 16.988V2.24879H85.8417V6.37039H81.0705V7.91599H85.4609V11.276H81.0705V12.8888H86.0881V16.988H76.3217Z"
          fill="white"
        />
        <path
          d="M90.1117 16.988L86.7965 6.28079H91.6797L92.6429 9.81999L93.3373 12.5976L94.0093 9.81999L94.9725 6.28079H99.1165L95.8013 16.988H90.1117Z"
          fill="white"
        />
        <path
          d="M105.121 17.3464C101.335 17.3464 99.1621 15.1512 99.1621 11.6344C99.1621 8.13999 101.268 5.94479 104.897 5.94479C108.346 5.94479 109.981 8.54319 109.981 12.1944V12.5304H103.799C103.933 13.4712 104.785 13.852 106.397 13.852C107.517 13.852 108.66 13.5832 109.713 13.0008V16.5176C108.1 17.0776 106.509 17.3464 105.121 17.3464ZM104.717 8.61039C104.09 8.61039 103.821 9.14799 103.777 10.4696H105.591C105.591 9.10319 105.345 8.61039 104.717 8.61039Z"
          fill="white"
        />
        <path
          d="M111.421 16.988V6.28079H115.99V7.62479C116.573 6.70639 117.558 6.01199 118.992 6.01199C120.829 6.01199 122.24 7.37839 122.24 9.55119V16.988H117.67V10.6712C117.67 10.1336 117.267 9.81999 116.797 9.81999C116.461 9.81999 116.147 9.93199 115.99 10.2008V16.988H111.421Z"
          fill="white"
        />
        <path
          d="M127.953 17.2568C125.758 17.2568 124.369 15.8008 124.369 13.4936V9.95439H123.137V6.28079H124.369V3.18959H128.939V6.28079H130.776V9.95439H128.939V12.1944C128.939 12.844 129.297 13.2696 129.88 13.2696C130.283 13.2696 130.641 13.1352 130.955 12.8888V16.6968C129.947 17.0776 128.984 17.2568 127.953 17.2568Z"
          fill="white"
        />
        <path
          d="M76.3903 29.16V22.9742H78.1013C79.4738 22.9742 80.1507 23.51 80.1507 24.5159C80.1507 25.2304 79.8029 25.7569 79.1636 25.9919C79.9439 26.1799 80.3481 26.697 80.3481 27.4584C80.3481 28.5677 79.643 29.16 78.2329 29.16H76.3903ZM77.4432 25.6064H78.1013C78.7029 25.6064 79.0978 25.315 79.0978 24.7227C79.0978 24.1587 78.7029 23.8861 78.1107 23.8861H77.4432V25.6064ZM77.4432 28.2481H78.2705C78.9004 28.2481 79.3046 27.9567 79.3046 27.355C79.3046 26.7722 78.9004 26.4713 78.2329 26.4713H77.4432V28.2481Z"
          fill="white"
        />
        <path
          d="M80.6644 31.1154H80.5892V30.1847H80.6644C81.5199 30.1847 81.9994 29.9591 82.3378 29.2728L82.2438 29.0096L80.702 24.7604H81.7926L82.6762 27.3268L82.9019 28.0789L83.1275 27.3268L84.0112 24.7604H85.0453L83.5317 28.9532C82.9207 30.6266 82.1968 31.1154 80.6644 31.1154Z"
          fill="white"
        />
        <path
          d="M89.4308 29.16V26.6405L87.1652 22.9742H88.3779L89.6283 25.033L89.9855 25.6816L90.3521 25.033L91.5931 22.9742H92.74L90.4838 26.6217V29.16H89.4308Z"
          fill="white"
        />
        <path
          d="M95.9786 29.301C94.0326 29.301 92.7541 27.9849 92.7541 26.0671C92.7541 24.1493 94.089 22.8332 95.9786 22.8332C97.8682 22.8332 99.1938 24.1023 99.1938 26.0671C99.1938 28.0319 97.9246 29.301 95.9786 29.301ZM95.9786 28.3327C97.2666 28.3327 98.1314 27.4396 98.1314 26.0671C98.1314 24.6945 97.2666 23.8015 95.9786 23.8015C94.6907 23.8015 93.8258 24.6945 93.8258 26.0671C93.8258 27.4396 94.6907 28.3327 95.9786 28.3327Z"
          fill="white"
        />
        <path
          d="M101.672 29.16L104.135 22.946H105.122L107.585 29.16H106.448L105.837 27.5806H103.355L102.753 29.16H101.672ZM103.693 26.6405H105.489L104.906 25.127L104.596 24.1963L104.276 25.127L103.693 26.6405Z"
          fill="white"
        />
        <path
          d="M109.865 31.247C109.263 31.247 108.68 31.1248 108.182 30.8898V29.8463C108.671 30.1189 109.179 30.3069 109.705 30.3069C110.457 30.3069 110.965 29.9121 110.965 29.16V28.7558C110.683 29.0848 110.26 29.254 109.818 29.254C108.633 29.254 107.91 28.4361 107.91 26.9978C107.91 25.6064 108.746 24.6663 110.025 24.6663C110.457 24.6663 110.993 24.8544 111.285 25.1552L111.51 24.7604H111.98V29.0566C111.98 30.3915 111.153 31.247 109.865 31.247ZM110.053 28.3515C110.429 28.3515 110.749 28.1729 110.965 27.9285V25.8039C110.758 25.6628 110.429 25.5594 110.138 25.5594C109.376 25.5594 108.934 26.1893 108.934 26.9884C108.934 27.8157 109.329 28.3515 110.053 28.3515Z"
          fill="white"
        />
        <path
          d="M115.058 29.254C113.657 29.254 112.84 28.3797 112.84 26.9508C112.84 25.5782 113.629 24.6663 114.889 24.6663C116.092 24.6663 116.826 25.456 116.826 26.9884V27.2892H113.883C113.902 27.9661 114.381 28.3891 115.199 28.3891C115.679 28.3891 116.196 28.2293 116.703 27.9191V28.8592C116.158 29.1224 115.603 29.254 115.058 29.254ZM114.889 25.503C114.297 25.503 113.921 25.9167 113.883 26.5277H115.801C115.801 25.8979 115.462 25.503 114.889 25.503Z"
          fill="white"
        />
        <path
          d="M117.682 29.16V24.7604H118.707V25.3526C119.026 24.9014 119.478 24.6663 119.995 24.6663C120.925 24.6663 121.48 25.268 121.48 26.3115V29.16H120.455V26.4995C120.455 25.8979 120.173 25.597 119.675 25.597C119.28 25.597 118.951 25.8039 118.707 26.1611V29.16H117.682Z"
          fill="white"
        />
        <path
          d="M124.598 29.254C123.188 29.254 122.314 28.3891 122.314 26.9696C122.314 25.5312 123.188 24.6663 124.589 24.6663C125.04 24.6663 125.51 24.7792 125.915 24.9954V26.0295C125.52 25.7569 125.106 25.6158 124.645 25.6158C123.837 25.6158 123.339 26.0859 123.339 26.9602C123.339 27.8157 123.856 28.3045 124.664 28.3045C125.125 28.3045 125.576 28.1165 125.933 27.8345V28.8686C125.557 29.113 125.059 29.254 124.598 29.254Z"
          fill="white"
        />
        <path
          d="M126.402 31.1154H126.327V30.1847H126.402C127.258 30.1847 127.737 29.9591 128.076 29.2728L127.982 29.0096L126.44 24.7604H127.53L128.414 27.3268L128.64 28.0789L128.865 27.3268L129.749 24.7604H130.783L129.27 28.9532C128.659 30.6266 127.935 31.1154 126.402 31.1154Z"
          fill="white"
        />
        <defs>
          <clipPath id="clip0_44_470">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg width="400" height="98" viewBox="0 0 400 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_44_263)">
        <mask id="mask0_44_263" maskUnits="userSpaceOnUse" x="0" y="0" width="98" height="98">
          <path d="M97.7437 0H0V97.7437H97.7437V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_44_263)">
          <path
            d="M42.3246 45.702H59.8064V69.8939H35.6146L42.3133 45.702H42.3246ZM81.137 0L62.5555 28.725H44.9165L53.1077 0H0V97.7437H97.7437V0H81.137Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M131.361 51.8897V6.8689H151.819L156.882 19.8688L159.209 27.1898L161.535 19.8688L166.598 6.8689H186.919V51.8897H172.414V30.6108L172.893 21.3741L169.677 30.5424L162.082 48.7423H154.488L146.893 30.474L143.746 21.4425L144.225 30.5424V51.8897H131.361Z"
        fill="white"
      />
      <path
        d="M193.286 66.9422H192.739V54.9686H193.286C199.307 54.9686 201.565 54.4212 203.686 51.8212L201.086 51.616L190.618 19.1846H205.602L208.202 29.9951L210.392 40.3266L212.649 29.9951L215.249 19.1846H228.181L219.834 46.7581C215.181 62.0843 206.834 66.9422 193.286 66.9422Z"
        fill="white"
      />
      <path
        d="M233.124 51.8897V6.8689H262.202V19.4583H247.629V24.1793H261.039V34.4424H247.629V39.3687H262.955V51.8897H233.124Z"
        fill="white"
      />
      <path
        d="M275.245 51.8897L265.119 19.1846H280.035L282.977 29.9951L285.098 38.4792L287.15 29.9951L290.092 19.1846H302.75L292.624 51.8897H275.245Z"
        fill="white"
      />
      <path
        d="M321.09 52.9844C309.527 52.9844 302.89 46.2792 302.89 35.5371C302.89 24.8635 309.321 18.1583 320.405 18.1583C330.942 18.1583 335.937 26.0951 335.937 37.2476V38.274H317.053C317.463 41.1476 320.063 42.3108 324.99 42.3108C328.411 42.3108 331.9 41.4897 335.116 39.7108V50.4528C330.19 52.1633 325.332 52.9844 321.09 52.9844ZM319.858 26.3003C317.942 26.3003 317.121 27.9424 316.984 31.9793H322.526C322.526 27.8056 321.774 26.3003 319.858 26.3003Z"
        fill="white"
      />
      <path
        d="M340.333 51.8897V19.1846H354.291V23.2898C356.07 20.4846 359.08 18.3636 363.459 18.3636C369.07 18.3636 373.38 22.5372 373.38 29.174V51.8897H359.422V32.595C359.422 30.9529 358.191 29.9951 356.754 29.9951C355.728 29.9951 354.77 30.3372 354.291 31.1582V51.8897H340.333Z"
        fill="white"
      />
      <path
        d="M390.832 52.7107C384.126 52.7107 379.884 48.2634 379.884 41.216V30.4056H376.121V19.1846H379.884V9.74256H393.842V19.1846H399.453V30.4056H393.842V37.2476C393.842 39.2318 394.937 40.5318 396.716 40.5318C397.947 40.5318 399.042 40.1213 400 39.3687V51.0002C396.921 52.1633 393.979 52.7107 390.832 52.7107Z"
        fill="white"
      />
      <path
        d="M233.333 89.069V70.1744H238.56C242.752 70.1744 244.819 71.8111 244.819 74.8837C244.819 77.066 243.757 78.6741 241.804 79.392C244.188 79.9663 245.422 81.5456 245.422 83.8715C245.422 87.2599 243.269 89.069 238.962 89.069H233.333ZM236.549 78.2146H238.56C240.397 78.2146 241.603 77.3245 241.603 75.5154C241.603 73.7925 240.397 72.9598 238.588 72.9598H236.549V78.2146ZM236.549 86.2836H239.076C241 86.2836 242.235 85.3934 242.235 83.5557C242.235 81.7753 241 80.8564 238.962 80.8564H236.549V86.2836Z"
        fill="white"
      />
      <path
        d="M246.389 95.0417H246.159V92.1989H246.389C249.002 92.1989 250.466 91.5098 251.5 89.4135L251.213 88.6095L246.504 75.6303H249.835L252.534 83.4695L253.223 85.7667L253.912 83.4695L256.611 75.6303H259.77L255.147 88.4372C253.28 93.5485 251.069 95.0417 246.389 95.0417Z"
        fill="white"
      />
      <path
        d="M273.166 89.069V81.3733L266.245 70.1744H269.949L273.769 76.463L274.86 78.4444L275.98 76.463L279.77 70.1744H283.273L276.382 81.3159V89.069H273.166Z"
        fill="white"
      />
      <path
        d="M293.166 89.4997C287.222 89.4997 283.316 85.4796 283.316 79.6217C283.316 73.7638 287.394 69.7437 293.166 69.7437C298.937 69.7437 302.986 73.6202 302.986 79.6217C302.986 85.6231 299.11 89.4997 293.166 89.4997ZM293.166 86.542C297.1 86.542 299.741 83.8141 299.741 79.6217C299.741 75.4293 297.1 72.7013 293.166 72.7013C289.232 72.7013 286.59 75.4293 286.59 79.6217C286.59 83.8141 289.232 86.542 293.166 86.542Z"
        fill="white"
      />
      <path
        d="M310.556 89.069L318.079 70.0882H321.094L328.618 89.069H325.143L323.277 84.2448H315.696L313.858 89.069H310.556ZM316.73 81.3733H322.214L320.434 76.7502L319.486 73.9074L318.51 76.7502L316.73 81.3733Z"
        fill="white"
      />
      <path
        d="M335.581 95.4437C333.744 95.4437 331.963 95.0704 330.441 94.3526V91.1652C331.935 91.9979 333.485 92.5722 335.093 92.5722C337.39 92.5722 338.941 91.3662 338.941 89.069V87.8342C338.08 88.8392 336.787 89.3561 335.438 89.3561C331.82 89.3561 329.609 86.8579 329.609 82.4645C329.609 78.2146 332.164 75.3431 336.07 75.3431C337.39 75.3431 339.027 75.9174 339.917 76.8363L340.607 75.6303H342.042V88.7531C342.042 92.8307 339.515 95.4437 335.581 95.4437ZM336.156 86.5995C337.304 86.5995 338.281 86.0539 338.941 85.3073V78.8176C338.309 78.3869 337.304 78.0711 336.414 78.0711C334.088 78.0711 332.739 79.995 332.739 82.4358C332.739 84.9627 333.945 86.5995 336.156 86.5995Z"
        fill="white"
      />
      <path
        d="M351.444 89.3561C347.165 89.3561 344.667 86.6856 344.667 82.3209C344.667 78.1285 347.079 75.3431 350.927 75.3431C354.603 75.3431 356.843 77.7552 356.843 82.4358V83.3546H347.855C347.912 85.4221 349.377 86.7143 351.875 86.7143C353.339 86.7143 354.919 86.2262 356.469 85.2786V88.1501C354.804 88.9541 353.11 89.3561 351.444 89.3561ZM350.927 77.8988C349.118 77.8988 347.97 79.1622 347.855 81.0287H353.713C353.713 79.1048 352.679 77.8988 350.927 77.8988Z"
        fill="white"
      />
      <path
        d="M359.458 89.069V75.6303H362.588V77.4393C363.565 76.061 364.943 75.3431 366.522 75.3431C369.365 75.3431 371.059 77.1809 371.059 80.3683V89.069H367.929V80.9426C367.929 79.1048 367.068 78.1859 365.546 78.1859C364.34 78.1859 363.335 78.8176 362.588 79.9088V89.069H359.458Z"
        fill="white"
      />
      <path
        d="M380.585 89.3561C376.277 89.3561 373.607 86.7143 373.607 82.3783C373.607 77.9849 376.277 75.3431 380.556 75.3431C381.934 75.3431 383.37 75.6877 384.605 76.3481V79.5068C383.399 78.6741 382.135 78.2433 380.728 78.2433C378.259 78.2433 376.737 79.6791 376.737 82.3496C376.737 84.9627 378.316 86.4559 380.786 86.4559C382.193 86.4559 383.571 85.8816 384.662 85.0201V88.1788C383.514 88.9254 381.992 89.3561 380.585 89.3561Z"
        fill="white"
      />
      <path
        d="M386.095 95.0417H385.865V92.1989H386.095C388.708 92.1989 390.172 91.5098 391.206 89.4135L390.919 88.6095L386.21 75.6303H389.541L392.24 83.4695L392.929 85.7667L393.618 83.4695L396.317 75.6303H399.476L394.853 88.4372C392.986 93.5485 390.775 95.0417 386.095 95.0417Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_44_263">
          <rect width="97.7437" height="97.7437" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default YoEventLogo;
