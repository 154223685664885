const USER = "yes_bo_user";

async function saveUserAndSession(newUser) {
  await localStorage.setItem(USER, JSON.stringify(newUser));
}

async function loadUserAndSession() {
  const user = await localStorage.getItem(USER);
  return JSON.parse(user);
}

async function clearUserAndSession() {
  await localStorage.removeItem(USER);
}

export default { loadUserAndSession, saveUserAndSession, clearUserAndSession };
