import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import "./Login.scss";
import YoEventLogo from "../../../assets/YoEventLogo";
import PasswordDisplayIcon from "../../../assets/PasswordDisplayIcon";

import InputField from "../../partials/InputField/InputField";
import { adminLogin } from "../../../services/login.service";
import Loader from "../../partials/Loader/Loader";
import { userState } from "../../../store/user";
import storage from "../../../utils/storage";

function Login() {
  const { t } = useTranslation();
  const history = useHistory();

  const setUser = useRecoilState(userState)[1];

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordDisplayed, setIsPasswordDisplayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const activeInputState = useState("");

  function login() {
    setIsLoading(true);
    setIsError(false);

    adminLogin({ email, password })
      .then(async (res) => {
        if (res && res.data) {
          await storage.saveUserAndSession(res.data);
          setUser(res.data.user);
          history.push("/backoffice/campaigns");
        } else {
          throw Error("no data received");
        }
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <main className="login" onKeyPress={handleKeyPress}>
      <div className="login-container">
        <YoEventLogo />
        <div className="account-content">
          <h1>Connexion</h1>
          <div className="centered-container">
            <InputField fieldName="email" value={email} setValue={setEmail} activeInputState={activeInputState} />
            <InputField
              fieldName="password"
              value={password}
              setValue={setPassword}
              activeInputState={activeInputState}
              isPasswordHidden={!isPasswordDisplayed}
            >
              <div className="password-display-toggle" onClick={() => setIsPasswordDisplayed(!isPasswordDisplayed)}>
                <PasswordDisplayIcon isOpen={isPasswordDisplayed} />
              </div>
            </InputField>
            <a className="link-forgot-password" href="mailto:contact@yoagency.fr">
              {t("userInformations.forgotPassword")}
            </a>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <button className="button-rounded" onClick={login}>
              {t("userInformations.connect")}
            </button>
          )}

          {isError && <div className="error">{t("pages.errorMessage")}</div>}
        </div>
      </div>
    </main>
  );
}

export default Login;
