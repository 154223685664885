import axios from "axios";
import storage from "../../utils/storage";

axios.interceptors.request.use(async (config) => {
  const userData = await storage.loadUserAndSession();

  if (userData && userData.session && userData.session.token) config.headers["Yes-Token"] = userData.session.token;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && !error.config.url.includes("Login")) {
      await storage.clearUserAndSession();
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

const baseUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:3030/";

const apiUrl = baseUrl + "";

const REST = {
  get(route, params = {}) {
    return axios.get(apiUrl + route, { params });
  },
  post(route, data) {
    return axios.post(apiUrl + route, data);
  },
  put(route, data) {
    return axios.put(apiUrl + route, data);
  },
  delete(route, data) {
    return axios.delete(apiUrl + route, { data });
  },
};

export default REST;
