import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";

import Routes from "./router/routes";
import "./styles/global.scss";
import "./translations/i18n";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root"),
);
